import styled from 'styled-components';

const StyledButton = styled.button`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 10%;

  font-size: 1.2em;
  color: var(--btn-text-color);

  background: transparent;
`;

export default StyledButton;
