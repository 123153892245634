import styled from 'styled-components';

const StyledTitle = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 10%;

  font-size: 1.2em;
`;

export default StyledTitle;
