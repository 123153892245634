enum EventName {
  ChangeArea,
  ChangeSystem,
  ChangeRoom,
  ChangeState,
  ChangeVideoCamera,
  ClickOnElectricGroup,
  ClickOnVideoCameraGroup,
  Stop3DRender,
  Start3DRender,
  ChangeSceneLoadingProgress,
}

export default EventName;
