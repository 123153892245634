import styled from 'styled-components';

const StyledPage = styled.section`
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 90%;

  color: var(--h1-text-color);

  background: var(--panel-background-color);
`;

export default StyledPage;
