import * as BABYLON from 'babylonjs';
// eslint-disable-next-line import/no-unresolved
import { ISceneLoaderAsyncResult } from 'babylonjs/Loading/sceneLoader';

import EventName from '../utils/EventName';
import pubSub from '../utils/pubSub';

export class ModelImporter {
  public static async import(
    scene: BABYLON.Scene
  ): Promise<ISceneLoaderAsyncResult> {
    const result = await BABYLON.SceneLoader.AppendAsync(
      '/models/',
      'plan.babylon',
      scene,
      (event) => {
        const percentLoaded = event.loaded / (event.total / 100);
        pubSub.publish(EventName.ChangeSceneLoadingProgress, percentLoaded);
      }
    );
    return result;
  }
}
